<template>
  <b-card
      footer-class="text-muted"
  >
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-1">
      <b-col style="margin-bottom: 40px"
             cols="12"
      >

        <h3 style="text-align: center">Paciente: {{ user.name }}</h3>
        <h4 v-if="select_medical" style="text-align: center; margin-top: 20px">Medico: {{ select_medical.value }}</h4>
        <div class="mt-3" style="display: flex; justify-content: center" align="center">
          <div>
            <img :src="avatarImage ? avatarImage : avatarImageTmp" style="width: 100px; margin-bottom: 20px"/>
            <b-form-file
                @change="handleImage()"
                id="file_patient_photo"
                placeholder="Subir Imagen."
                drop-placeholder="Drop file here..."
            />
          </div>
        </div>
        <div align="center" style="margin-top: 40px">
          <b-button
              @click="generatePdfAll"
              style="width: 30%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mr-1"
          >
            Generar historia del paciente
          </b-button>
        </div>

      </b-col>

      <b-col
          lg="6"
      >

        <h4 style="text-align: center" v-if="user.birthday">Fecha de nacimiento: {{ dateStringMx(user.birthday) }}</h4>
        <h4 style="text-align: center" v-if="user.birthday">Edad: {{ yearsCalculate(user.birthday) }} Años</h4>
      </b-col>
      <b-col
          lg="6"
      >
        <h4 style="text-align: center">Numero expediente: {{ user.patient_file_number }}</h4>
        <h4 style="text-align: center">Celular: {{ user.mobile }}</h4>

      </b-col>
    </b-row>
    <b-form @submit.prevent="updateUser(user)">
      <b-row>
        <b-col
            cols="12" lg="12"
        >
          <app-collapse>
            <app-collapse-item title="Información General" :is-visible="true">
              <b-row>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Nombre"
                      label-for="Nombre"
                  >
                    <b-form-input
                        v-model="user.name"
                        id="basicInput"
                        placeholder="Paciente"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="2"
                >
                  <b-form-group

                      label="Numero expediente"
                      label-for="Numero expediente"
                  >
                    <b-form-input
                        v-model="user.patient_file_number"
                        readonly="readonly"
                        placeholder="Numbero expediente"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group>
                    <h5>Fecha de nacimiento</h5>
                    <flat-pickr
                        v-model="user.birthday"
                        class="form-control"
                        :config="{ enableTime: false,dateFormat: 'Y-m-d',  locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group label="Sexo" label-for="h-email">
                    <v-select
                        v-model="gender_select"
                        @input="changeGender()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="genders"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Género:" label-for="h-email">
                    <v-select
                        v-model="identified_gender_select"
                        @input="changeIdentifiedGender()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="gendersIdentified"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group

                      label="Puesto de trabajo"
                      label-for="Puesto de trabajo"
                  >
                    <b-form-input
                        v-model="user.function"
                        placeholder="Puesto de trabajo"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Telefono"
                      label-for="Telefono"
                  >
                    <b-form-input
                        @keypress="isNumber($event)"
                        v-model="user.phone"
                        placeholder="Telefono"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Celular"
                      label-for="Celular"
                  >
                    <b-form-input
                        @keypress="isNumber($event)"
                        v-model="user.mobile"
                        placeholder="Celular"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Correo"
                      label-for="Correo"
                  >
                    <b-form-input
                        v-model="user.email"
                        type="email"
                        placeholder="Correo"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Doctores"
                      label-for="h-email"
                  >
                    <v-select
                        @input="changeMedical"
                        v-model="select_medical"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="medicals"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4" style="margin-top: 15px">
                  <b-form-checkbox
                      @change="changeExtraValue()"
                      v-model="user.check_medical"
                      :value="user.check_medical ? true : ''"
                  >
                    Medico Relacionado
                  </b-form-checkbox>
                  <div v-if="user.check_medical" style="margin-top: 15px">
                    <b-form-group
                        label="Medico Relacionado"
                        label-for="h-email"
                    >
                      <v-select
                          @input="change_extra_medical"

                          v-model="extra_select"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="value"
                          :options="medicals"
                      />
                    </b-form-group>
                  </div>
                </b-col>

                <b-col cols="6" lg="4" style="margin-top: 15px">
                  <b-form-checkbox
                      @change="changeChemoValue()"
                      v-model="user.chemo_suspension"
                      :value="user.chemo_suspension ? true : ''"
                  >
                    Suspension de quimioterapia
                  </b-form-checkbox>
                  <div v-if="user.chemo_suspension" style="margin-top: 15px">
                    <b-form-group
                        label="Razón"
                        label-for="h-email"
                    >
                      <v-select
                          @input="change_chemo"

                          v-model="chemo_select"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="value"
                          :options="chemo_options"
                      />
                    </b-form-group>
                  </div>
                  <div v-if="user.chemo_suspension" style="margin-top: 15px">
                    <b-form-group
                        label="Comentarios"
                        label-for="h-email"
                    >
                      <b-form-textarea v-model="user.comments_suspension">

                      </b-form-textarea>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="6" lg="4" style="margin-top: 15px">
                  <div v-if="allergiesInfo" style="margin-top: 15px">
                    <b-form-group
                        label="Allergias"
                        label-for="h-email"
                    >
                      <b-form-textarea v-model="allergiesInfo" disabled>

                      </b-form-textarea>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Domicilio">
              <b-row>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="País"
                      label-for="h-email"
                  >
                    <b-form-input
                        v-if="!active_select_country"
                        v-model="name_country"
                        @click="active_select_country = true"
                        placeholder="País"
                    />
                    <vue-autosuggest
                        v-else
                        :suggestions="countries"
                        :input-props="{id:'',class:'form-control', placeholder:'Seleccionar país'}"
                        @input="getCountrySelect"
                        @selected="countryVerify"

                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                      </template>
                    </vue-autosuggest>


                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Estado"
                      label-for="h-email"
                  >
                    <v-select
                        @input="selectState()"
                        v-model="select_state"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="states"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Municipios"
                      label-for="h-email"
                  >
                    <v-select
                        v-model="select_cities"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="cities"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group

                      label="Colonia"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.l10n_mx_edi_colony"
                        id="colony"
                        placeholder="Colonia"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Codigo postal"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.zip"
                        @keypress="isNumber($event)"
                        id="zip"
                        placeholder="Codigo postal"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Calle"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.street_name"
                        id="name"
                        placeholder="Calle"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                  <b-form-group
                      label="Numero Casa"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.street_number"
                        id="num"
                        placeholder="Numero Casa"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Información de facturación">
              <b-row>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Tipo de Paciente"
                      label-for="h-email"
                  >
                    <v-select
                        @input="pricelistVerify()"
                        v-model="select_price_list"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="pricelists"
                    />
                  </b-form-group>

                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group

                      label="Rfc"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.vat"
                        id="rfc"
                        placeholder="rfc"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Regimen Fiscal" label-for="h-email">
                    <v-select
                        v-model="regimen_select"
                        @input="changeRegimen()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="regimens"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-col>
        <b-col cols="12" style="margin-top: 30px">
          <b-button
              style="width: 100%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Guardar
          </b-button>

        </b-col>
      </b-row>

    </b-form>

    <b-tabs style="margin-top: 30px" v-if="user.id"  >
      <b-tab active title="Información / H.C." @click="executeInfoHistory">
        <InformationPatient title="Información / H.C." :data_patient="user" :reload="initialize"/>
      </b-tab>
      <b-tab title="Archivos">
        <FilesPatient title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Consultas" v-if="user" @click="executeConsultation">
        <ConsultationsPatient v-if="user" title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Recetas" v-if="user" @click="executeRecipe">
        <RecipePatient v-if="user" title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Esquemas" v-if="user" @click="executeSchema">
        <PatientChemotherapy v-if="user" title="Esquemas" :data_patient="user"/>
      </b-tab>
      <b-tab title="Compartir" v-if="user" @click="executeToShare">
        <PatientShare v-if="user" title="Compartir" :data_patient="user"/>
      </b-tab>
      <b-tab title="Cuestionario" v-if="user" @click="executeToQuestion">
        <PatientQuestionnaire v-if="user" title="Cuestionario" :data_patient="user"/>
      </b-tab>
      <b-tab title="Biocomposiciones" v-if="user" @click="executeToBio">
        <FilesBiocompositions v-if="user" title="Biocomposiciones" :data_patient="user"/>
      </b-tab>

      <b-tab title="Estudios de Laboratorio" v-if="user" @click="executeToLaboratories">
        <FilesLaboratories v-if="user" title="Estudios de Laboratorio" :data_patient="user"/>
      </b-tab>
      <b-tab title="Aseguradoras" v-if="user" @click="executeInsurers">
        <InsurersPatient v-if="user" title="Aseguradoras" :data_patient="user"/>
      </b-tab>
      <!-- <b-tab title="Archivos de tratamientos" v-if="user" @click="executeFilesTreatment">
        <FilesTreatment v-if="user" title="Archivos de tratamientos" :data_patient="user"/>
      </b-tab> -->
      <b-tab title="Inter Consulta" v-if="user" @click="executeInterConsultations">
        <InterConsultationsPatient v-if="user" title="Inter Consulta" :data_patient="user"/>
      </b-tab>
    </b-tabs>
  </b-card>

</template>

<script>

import {
  BTabs, BTab, BCard, BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BButton, BForm, BFormCheckbox, BOverlay, BFormFile
} from 'bootstrap-vue'
import {mapGetters, mapActions} from "vuex";
import PatientForms from "@/components/Patients/PatientForms";
import HistoricClinic from "@/components/Patients/HistoricClinic";
import InformationPatient from "@/components/Patients/InformationPatient";
import PatientChemotherapy from "@/components/Patients/PatientChemotherapy";
import FilesPatient from "@/components/Patients/FilesPatient";
import RecipePatient from "@/components/Patients/RecipePatient";
import ConsultationsPatient from "@/components/Patients/ConsultationsPatient";
import PatientShare from "@/components/Patients/PatientShare";
import {VueAutosuggest} from 'vue-autosuggest'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FilesBiocompositions from "@/components/Patients/FilesBiocompositions";
import FilesLaboratories from "@/components/Patients/FilesLaboratories";
import FilesTreatment from "@/components/Patients/FilesTreatment";
import PatientQuestionnaire from "@/components/Patients/PatientQuestionnaire";
import InsurersPatient from "@/components/Patients/InsurersPatient";
import InterConsultationsPatient from "@/components/Patients/InterConsultationsPatient";
import jsPDF from "jspdf";
import logo from "@/assets/images/logo/logo-1.png";
import {formatHour} from "@/utils/date";

export default {
  name: "DetailPatient",
  components: {
    BFormFile,
    BOverlay,
    BForm,
    BFormCheckbox,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
    BButton, BFormSelect,
    PatientForms,
    vSelect,
    VueAutosuggest,
    flatPickr,
    HistoricClinic,
    InformationPatient,
    FilesPatient,
    ConsultationsPatient, RecipePatient, PatientChemotherapy, PatientShare,
    AppCollapse,
    AppCollapseItem,
    FilesBiocompositions,
    FilesLaboratories,
    PatientQuestionnaire, InsurersPatient, FilesTreatment, InterConsultationsPatient
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabs: [],
      chemo_select: {id: "", value: "Seleccione una razón"},
      extra_select: {id: "", value: "Seleccione un medico"},
      chemo_options: [
        {id: "Paciente Fallecido", value: "Paciente Fallecido"},
        {id: "Alta Paciente", value: "Alta Paciente"},
        {id: "En espera de resultado de PET CT", value: "En espera de resultado de PET CT"},
      ],
      regimen_select: {id: "", value: "Seleccione un regimen"},
      gender_select: {id: "", value: "Seleccione un genero"},
      identified_gender_select: {id: "", value: "Seleccione una opción"},
      gendersIdentified: [
        {
          id: "No especificado",
          value: "No especificado"
        },
        {
          id: "F",
          value: "Femenino"
        },
        {
          id: "M",
          value: "Masculino"
        },
        {
          id: "Transgenero",
          value: "Transgénero"
        },
        {
          id: "Transexual",
          value: "Transexual"
        },
        {
          id: "Travesti",
          value: "Travesti"
        },
        {
          id: "Intersexual",
          value: "Intersexual"
        },
        {
          id: "No-Binario",
          value: "No-Binario"
        },
        {
          id: "Otro",
          value: "Otro"
        },
      ],
      genders: [
        {
          id: "M",
          value: "Masculino"
        },
        {
          id: "F",
          value: "Femenino"
        }
      ],
      regimens: [
        {id: "601", value: "General de Ley Personas Morales"},
        {id: "603", value: "Personas Morales con Fines no Lucrativos"},
        {id: "605", value: "Sueldos y Salarios e Ingresos Asimilados a Salarios"},
        {id: "606", value: "Arrendamiento"},
        {id: "608", value: "Demás ingresos"},
        {id: "609", value: "Consolidación"},
        {id: "610", value: "Residentes en el Extranjero sin Establecimiento Permanente en México"},
        {id: "611", value: "Ingresos por Dividendos (socios y accionistas)"},
        {id: "612", value: "Personas Físicas con Actividades Empresariales y Profesionales"},
        {id: "614", value: "Ingresos por intereses"},
        {id: "616", value: "Sin obligaciones fiscales"},
        {id: "620", value: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos"},
        {id: "621", value: "Incorporación Fiscal"},
        {id: "622", value: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"},
        {id: "623", value: "Opcional para Grupos de Sociedades"},
        {id: "624", value: "Coordinados"},
        {id: "628", value: "Hidrocarburos"},
        {id: "607", value: "Régimen de Enajenación o Adquisición de Bienes"},
        {id: "629", value: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales"},
        {id: "630", value: "Enajenación de acciones en bolsa de valores"},
        {id: "615", value: "Régimen de los ingresos por obtención de premios"},
        {
          id: "625",
          value: "Régimen de las actividades empresariales con ingresos a traves de plataformas inalambricas"
        },
        {id: "626", value: "Régimen Simplificado de confianza"},
      ],
      select_state: {id: '', value: ''},
      select_cities: {id: '', value: ''},
      select_medical: {id: '', value: 'Seleccione un medico'},
      countries: [],
      name_country: '',
      active_select_country: false,
      allergiesInfo: null,
      states: [],
      cities: [],
      pricelists: [],
      medicals: [],
      idactive: null,
      avatarImage: null,
      avatarImageTmp: require('@/assets/images/avatar.jpeg'),
      select_group: {id: '', value: ''},
      groups: [],
      patient_id: null,
      note_evolution: null,
      show: false,
      select_price_list: {id: '', value: ''},
      get_country: '',
      user: {
        id: '',
        name: '',
        reason_suspension: '',
        patient_file_number: '',
        comments_suspension: '',
        gender: '',
        l10n_mx_edi_fiscal_regime: '',
        property_product_pricelist: '',
        phone: '',
        mobile: '',
        email: '',
        function: '',
        appointee_id: '',
        is_patient: true,
        chemo_suspension: false,
        street: '',
        street_number: '',
        city_id: '',
        state_id: '',
        country_id: '',
        zip: '',
        l10n_mx_edi_colony: '',
        l10n_mx_edi_locality: '',
        vat: '',
        check_medical: false,
        extra_medical_id: null,
        identified_gender: null,
      }
    }
  },
  async created() {
    await this.initialize();
  },

  methods: {
    ...mapActions('patient', ['createPatient', 'findConsecutiveId', 'findPatientOne',
      'updatePatient', 'findPricelistPatient', 'createInformationGeneral','updatePhotoPatient','findPatientOneInformation','findPatientOneHistory','findPatientAllPrintConsultations','getFindFullName']),
    ...mapActions('appointment', ['findAppointmentDoctor', 'findAppointmentGroups', 'findApointeeSearch', 'findAppointmentDoctorPlace', 'findAppointmentPriceList']),
    ...mapActions('clinic_patient', ['nameClinic']),
    ...mapActions('address', ['findCountry', 'findCountryState', 'findCountryCities']),
    formSmokeCalculate(responseHistory) {
      if (!responseHistory.tobacco_day) return
      if (!responseHistory.tobacco_years) return
      return this.smokeForm(responseHistory.tobacco_years, responseHistory.tobacco_day)

    },
    async generatePdfAll(){
      try{
        this.show = true;
        const responseHistory = await this.findPatientOneHistory(this.user.id);
        const responseConsultations = await this.findPatientAllPrintConsultations(this.user.id);
        const totalSmoke = this.formSmokeCalculate(responseHistory)
        const options = {
          orientation: "p",
          unit: "cm",
          format: "a4",
        };
        var doc = new jsPDF(options);


        const a = this.user.name;
        const pageHeight = doc.internal.pageSize.height;
        doc.setFontSize(8);
        const img = new Image();
        img.src = logo;
        doc.addImage(img, "png", 1, 1, 4, 2);
        doc.setFontSize(12);
        doc.text(`Historia Clinica`, 9, 1.5);
        doc.setFontSize(10);
        doc.text(`Nombre Paciente:`, 9, 2.5);
        doc.text(`${this.user.name}`, 9, 3);
        doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 1.5, 4.3);
        doc.text(
            `RFC: ${
                this.getUser.ResPartnerAuth?.vat ? this.getUser.ResPartnerAuth?.vat : ""
            }`,
            12,
            4.3
        );
        doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 1.5, 5);
        doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} / ${this.getUser.study_school ? this.getUser.study_school : ''}`, 1.5, 5.8);

        if (this.user.birthday) {
          doc.text(`Fecha de nacimiento:`, 16, 2.5);
          doc.text(`${this.user.birthday ? this.dateStringMxFormat(this.user.birthday) : ""}`, 16, 3);
        } else {
          doc.text(`Fecha de nacimiento:`, 16, 2.5);
        }

        if (this.user.birthday) {
          doc.text(`Años: ${this.user.birthday ? `${this.yearsCalculate(this.user.birthday)} Años` : ""}`, 16, 1.5);
        }
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text(`Información General`, 9, 6.8);
        doc.text(`Paciente: ${this.user.name}`, 1.5, 7.5);
        doc.text(`Numero de expediente: ${this.user.patient_file_number}`, 1.5, 8.2);
        doc.text(`Celular: ${this.user.mobile}`, 12, 8.2);

        const searchSex= this.genders.find((data) => data.id === this.user.gender)
        doc.text(`Sexo: ${searchSex.value}`, 1.5, 9);
        let searchIdentified = ''
        if (this.user.identified_gender){
          searchIdentified = this.gendersIdentified.find((data) => data.id === this.user.identified_gender)
        }


        doc.text(`Género: ${searchIdentified.value}`, 7, 9);
        doc.text(`Puesto de trabajo: ${this.user.function}`, 12, 9);
        doc.text(`Correo: ${this.user.email}`, 1.5, 10);
        doc.text(`Doctor: ${this.user.ResPartnerAppointment.name}`, 1.5, 11);
        if (this.user.check_medical){
          doc.text(`Medico relacionado: ${this.extra_select.value}`, 1.5, 12);
        }
        if (this.user.chemo_suspension){
          doc.text(`Razon se suspesión: ${this.chemo_select.value}`, 1.5, 13);
        }

        const lineWidth = 0.1;

        doc.setLineWidth(lineWidth);
        doc.line(1.5, 14.5, 20, 14.5);
        doc.text(`Domicilio`, 9, 15.8);
        doc.text(`País: ${this.user.ResCountry.name}`, 1.5, 16.8);
        doc.text(`Estado: ${this.user.ResCountryState.name}`, 5.5, 16.8);
        doc.text(`Municipio: ${this.user.ResCity.name}`, 11, 16.8);
        doc.text(`${this.user.street}, ${this.user.street_number}, ${this.user.zip}, ${this.user.l10n_mx_edi_colony}`, 1.5, 17.8);

        doc.setLineWidth(lineWidth);
        doc.line(1.5, 18.8, 20, 18.8);
        doc.text(`Información de facturación`, 8, 19.8);
        doc.text(`RFC: ${this.user.vat}`, 1.5, 20.8);
        doc.text(`Tipo de Paciente: ${this.select_price_list.value}`, 6.8, 20.8);
        doc.text(`Regimen fiscal: ${this.regimen_select.value}`, 1.5, 21.8);
        doc.addPage();
        img.src = logo;
        doc.addImage(img, "png", 1, 1, 4, 2);
        doc.setFontSize(12);
        doc.text(`Historia Clinica`, 9, 1.5);
        doc.setFontSize(10);
        doc.text(`Nombre Paciente:`, 9, 2.5);
        doc.text(`${this.user.name}`, 9, 3);
        doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 1.5, 4.3);
        doc.text(
            `RFC: ${
                this.getUser.ResPartnerAuth?.vat ? this.getUser.ResPartnerAuth?.vat : ""
            }`,
            12,
            4.3
        );
        doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 1.5, 5);
        doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} / ${this.getUser.study_school ? this.getUser.study_school : ''}`, 1.5, 5.8);

        if (this.user.birthday) {
          doc.text(`Fecha de nacimiento:`, 16, 2.5);
          doc.text(`${this.user.birthday ? this.dateStringMxFormat(this.user.birthday) : ""}`, 16, 3);
        } else {
          doc.text(`Fecha de nacimiento:`, 16, 2.5);
        }

        if (this.user.birthday) {
          doc.text(`Años: ${this.user.birthday ? `${this.yearsCalculate(this.user.birthday)} Años` : ""}`, 16, 1.5);
        }
        doc.setFontSize(13);
        doc.text(`Antecedentes Familiares`, 8, 6.5);
        let sufferings = [];
        doc.setFontSize(10);
        sufferings.push({
          response: responseHistory.diabetes_mellitus ? 'Si' : 'No',
          suffering: 'Diabetes',
          description: responseHistory.diabetes_observations,
        })
        sufferings.push({
          response: responseHistory.cancer ? 'Si' : 'No',
          suffering: 'Cancer',
          description: responseHistory.cancer_observations,
        })
        sufferings.push({
          response: responseHistory.arterial_hypertension ? 'Si' : 'No',
          suffering: 'Hipertensión Arterial',
          description: responseHistory.arterial_hypertension_observations,
        })
        const margin = 1.5;

        let tableHeight = margin;
        sufferings.forEach(suffering => {
          tableHeight += 1;
        });

        const startY = 3 + tableHeight;
        doc.autoTable({
          columns: [
            {header: "Padecimiento", dataKey: "suffering"},
            {header: "Respuesta", dataKey: "response"},
            {header: "Descripción", dataKey: "description"},
          ],
          startY: startY,
          headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
          bodyStyles: {fontSize: 8},
          body: sufferings,
        })
        doc.setFontSize(10);
        let currentHeight = startY + (margin + 2);

        if (responseHistory.other_diseases) {
          doc.text("Otros antecedentes:", 1.5, startY + (margin + 2));
          const strArr = doc.splitTextToSize(responseHistory.other_diseases, 18)
          currentHeight += 0.5;
          strArr.forEach((line, index) => {
            if (this.checkAndAddNewPage(currentHeight, pageHeight, doc)) {
              currentHeight = 2;
            }
            doc.text(line, 1.5, currentHeight);
            currentHeight += 0.7;
          });
        }

        const heightSum = currentHeight + 2
        doc.setFontSize(13);
        doc.text("Antecedentes Personales Patológicos:", 7, heightSum);
        doc.setFontSize(10);
        let personal = []
        personal.push({
          response: responseHistory.diatebes_personal ? 'Si' : 'No',
          suffering: 'Diabetes',
          description: responseHistory.diabetes_personal_observations,
          type: '-----',
        })
        personal.push({
          response: responseHistory.cancer_personal ? 'Si' : 'No',
          suffering: 'Cancer',
          description: responseHistory.cancer_personal_observations,
          type: '-----',
        })
        personal.push({
          response: responseHistory.hepatitis ? 'Si' : 'No',
          suffering: 'Hepatitis',
          description: responseHistory.hepatitis_observations,
          type: responseHistory.hepatitis_type,
        })
        personal.push({
          response: responseHistory.surgeries ? 'Si' : 'No',
          suffering: 'Cirugías',
          description: responseHistory.surgeries_observations,
          type: '-----',
        })
        personal.push({
          response: responseHistory.arterial_hypertension ? 'Si' : 'No',
          suffering: 'Hipertensión Arterial',
          description: responseHistory.arterial_personal_observations,
          type: '-----',
        })
        personal.push({
          response: responseHistory.medicines ? 'Si' : 'No',
          suffering: 'Ingesta de medicamentos',
          description: responseHistory.medicines_observations,
          type: '-----',
        })
        let tableHeightPersonal = heightSum;

        const startYPersonal = 0.5 + tableHeightPersonal;
        doc.autoTable({
          columns: [
            {header: "Padecimiento", dataKey: "suffering"},
            {header: "Respuesta", dataKey: "response"},
            {header: "Descripción", dataKey: "description"},
            {header: "Tipo", dataKey: "type"},
          ],
          startY: startYPersonal,
          headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
          bodyStyles: {fontSize: 8},
          body: personal,
          didDrawPage: function(data) {
            tableHeightPersonal = data.cursor.y;
          },
        })

        currentHeight += 4 + 0.5;
        if (this.checkAndAddNewPageHistory(tableHeightPersonal, pageHeight, doc)) {
          tableHeightPersonal = 2;
        }
        const table2Height = tableHeightPersonal + 1;
        doc.text("Grupo sanguineo:", 1.5, table2Height);
        doc.text(responseHistory.blood_group, 1.5, table2Height + 0.5);
        doc.text("Factor RH:", 5, table2Height);
        doc.text(responseHistory.rh, 5, table2Height + 0.5);
        doc.setFontSize(13);
        doc.text("Antecedentes Personales NO Patológicos:", 7, table2Height + 1.5);
        doc.setFontSize(10);
        doc.text(`Toma Bebidas Alcohólicas: ${responseHistory.alcoholism ? 'Si' : 'No'}`, 1.5, table2Height + 2.5);
        doc.text(`Cuanto topa por dia: ${responseHistory.alcoholism ? responseHistory.alcoholism_day : '----'}`, 1.5, table2Height + 3.0);
        doc.text(`Cuanto topa por semana: ${responseHistory.alcoholism ? responseHistory.alcoholism_week : '----'}`, 9.5, table2Height + 3.0);
        const heightPersonalSpace = table2Height + 3.0;
        doc.text(`Fuma: ${responseHistory.smoking ? 'Si' : 'No'}`, 1.5, heightPersonalSpace + 1);
        doc.text(`Veces que fuma: ${responseHistory.smoking ? responseHistory.smoking_observations : '----'}`, 1.5, heightPersonalSpace + 1.5);
        doc.text(`Años fumando: ${responseHistory.smoking ? responseHistory.tobacco_years : '----'}`, 5, heightPersonalSpace + 1.5);
        doc.text(`Cantidad de cigarros al dia: ${responseHistory.smoking ? responseHistory.tobacco_day : '----'}`, 9, heightPersonalSpace + 1.5);
        doc.text(`Indice tabaquico: ${responseHistory.smoking ? totalSmoke : '----'}`, 15, heightPersonalSpace + 1.5);
        const heightPersonalSpaceDrugs = heightPersonalSpace + 1.5;
        doc.text(`Drogas: ${responseHistory.drug_intake ? 'Si' : 'No'}`, 1.5, heightPersonalSpaceDrugs + 1);
        doc.text(`Veces que consume drogas: ${responseHistory.drug_intake_observations ? responseHistory.drug_intake_observations : '----'}`, 1.5, heightPersonalSpaceDrugs + 1.5);
        const heightPersonalSpaceExercise = heightPersonalSpaceDrugs + 1.5;
        doc.text(`Ejercicio: ${responseHistory.exercise ? 'Si' : 'No'}`, 1.5, heightPersonalSpaceExercise + 1);
        doc.text(`Veces que consume drogas: ${responseHistory.exercise ? responseHistory.exercise_observations : '----'}`, 1.5, heightPersonalSpaceExercise + 1.5);
        doc.addPage();
        doc.setFontSize(13);
        doc.text("Antecedentes Ginecológicos:", 7, 4.5);
        doc.setFontSize(10);
        doc.text(`Menarca: ${responseHistory.menarche}`, 1.5, 5.5);
        doc.text(`Gestaciones: ${responseHistory.gestations ? 'Si' : 'No'}`, 1.5, 6.5);
        doc.text(`Fecha de ultima gestación: ${responseHistory.gestations ? this.dateStringMxFormat(responseHistory.date_gestations) : '---'}`, 7.5, 6.5);
        doc.text(`Partos: ${responseHistory.gestations ? responseHistory.childbirth : '---'}`, 1.5, 7);
        doc.text(`Cesáreas: ${responseHistory.gestations ? responseHistory.caesarean : '---'}`, 4.5, 7);
        doc.text(`Abortos: ${responseHistory.gestations ? responseHistory.abortions : '---'}`, 7.5, 7);
        doc.text(`Óbitos: ${responseHistory.gestations ? responseHistory.deaths : '---'}`, 10.5, 7);
        doc.text(`Ectópicos: ${responseHistory.gestations ? responseHistory.ectopic : '---'}`, 13.5, 7);
        doc.text(`Comentarios:`, 1.5, 8);

        const strArrBackGestations = doc.splitTextToSize(responseHistory.comments_menarche, 18)
        const sumOtherGestations = 8.5;
        doc.text(strArrBackGestations, 1.5, sumOtherGestations);
        const firstParaHeight2 = doc.getTextDimensions(strArrBackGestations, {fontSize: doc.internal.getFontSize()}).h;
        doc.text(strArrBackGestations, 1.5, sumOtherGestations);
        const sumGestations = sumOtherGestations + firstParaHeight2

        doc.text(`Papanicolau: ${responseHistory.papanicolau ? 'Si' : 'No'}`, 1.5, sumGestations + 2);
        doc.text(`Fecha de ultimo papanicolau: ${responseHistory.papanicolau ? this.dateStringMxFormat(responseHistory.date_papanicolau) : '---'}`, 7.5, sumGestations + 2);
        doc.text(`Comentarios:`, 1.5, sumGestations + 3);

        const strArrPapanicolau = doc.splitTextToSize(responseHistory.comments_papanicolau ? responseHistory.comments_papanicolau : '', 18)
        const sumOtherPapanicolau = sumGestations + 3.5;
        doc.text(strArrPapanicolau, 1.5, sumOtherPapanicolau);
        const firstParaHeight3 = doc.getTextDimensions(strArrPapanicolau, {fontSize: doc.internal.getFontSize()}).h;
        const sumPapanicolau = sumOtherPapanicolau + firstParaHeight3
        doc.text(`Mamografía: ${responseHistory.mammography ? 'Si' : 'No'}`, 1.5, sumPapanicolau + 2);
        doc.text(`Fecha de ultimo mamografía: ${responseHistory.mammography ? this.dateStringMxFormat(responseHistory.date_mammography) : '---'}`, 7.5, sumPapanicolau + 2);
        doc.text(`Comentarios:`, 1.5, sumPapanicolau + 3);
        const strArrMammography = doc.splitTextToSize(responseHistory.comments_mammography ? responseHistory.comments_mammography : '', 18)
        const sumOtherMammography = sumPapanicolau + 3.5;
        doc.text(strArrMammography ? strArrMammography : '---', 1.5, sumOtherMammography);
        const firstParaHeight4 = doc.getTextDimensions(strArrMammography, {fontSize: doc.internal.getFontSize()}).h;
        const sumMammography = sumOtherMammography + firstParaHeight4
        doc.text(`Continúa Menstruando: ${responseHistory.continue_menstruating ? 'Si' : 'No'}`, 1.5, sumMammography + 2);
        doc.text(`Desde cuando: ${responseHistory.date_rule_no ? this.dateStringMxFormat(responseHistory.date_rule_no) : '---'}`, 7.5, sumMammography + 2);
        doc.text(`Fecha de ultima Regla: ${responseHistory.continue_menstruating ? this.dateStringMxFormat(responseHistory.date_rule) : '---'}`, 12.5, sumMammography + 2);
        doc.text(`Duración (Días): ${responseHistory.continue_menstruating ? responseHistory.duration_menstruating : '---'}`, 1.5, sumMammography + 3);
        doc.text(`Frecuencia (Días): ${responseHistory.continue_menstruating ? responseHistory.frequency_menstruating : '---'}`, 5.5, sumMammography + 3);
        doc.text(`Ciclos mentruales irregulares: ${responseHistory.continue_menstruating ? responseHistory.irregular_menstrual ? 'Si' : 'No' : '---'}`, 9.5, sumMammography + 3);
        doc.text(`Anticonceptivos: ${responseHistory.continue_menstruating ? responseHistory.contraceptives ? 'Si' : 'No' : '---'}`, 15.5, sumMammography + 3);

        doc.text(`Comentarios Generales:`, 1.5, sumMammography + 5);
        const strArrAll = doc.splitTextToSize(responseHistory.comments ? responseHistory.comments : '', 18)
        const sumOtherAll = sumMammography + 5.5;
        doc.text(strArrAll, 1.5, sumOtherAll);
        const firstParaHeight5 = doc.getTextDimensions(strArrAll, {fontSize: doc.internal.getFontSize()}).h;
        const sumAll = sumOtherAll + firstParaHeight5
        doc.text(`Diagnostico :`, 1.5, sumAll + 3);


        doc.addPage();
        doc.setFontSize(11);
        const addTextSection = (doc, title, content, x, y, maxWidth) => {
          doc.setFont("helvetica", "bold");
          doc.text(title, x, y);
          const splitText = doc.splitTextToSize(content, maxWidth);
          doc.setFont("helvetica", "normal");
          doc.text(splitText, x, y +  .5);
          const height = doc.getTextDimensions(splitText, {fontSize: doc.internal.getFontSize()}).h;
          return y + .5 + height;
        };
        let responseInfo = await this.findPatientOneInformation(this.user.id);
        let currentY = 2.5;
        const startX = 1.5;
        const maxWidth = 18;
        const importantBackground = responseInfo.important_background || '';
        currentY = addTextSection(doc, "Antecedentes Importantes:", importantBackground, startX, currentY + .5, maxWidth);

        if (responseInfo.have_allergies) {
          const allergies = responseInfo.allergies || '';
          currentY = addTextSection(doc, "Alergias:", allergies, startX, currentY + 1, maxWidth);
        }

        const psychologicalTreatment = responseInfo.psychological_treatment || '';
        currentY = addTextSection(doc, "Tratamiento Psicológico/Psiquiátrico:", psychologicalTreatment, startX, currentY + 1, maxWidth);

        const referPatient = responseInfo.refers_patient || '';
        currentY = addTextSection(doc, "Quién Refiere al paciente:", referPatient, startX, currentY + 1, maxWidth);

        const reasonConsulting =responseInfo.reason || '';
        currentY = addTextSection(doc, "Motivo de la consulta (Según el paciente):", reasonConsulting, startX, currentY + 1, maxWidth);


        const evolutionActually = responseInfo.observations || '';
        currentY = addTextSection(doc, "Evolución Padecimiento Actual:", evolutionActually, startX, currentY + 1, maxWidth);
        doc.addPage();

        const ptToCm = pt => pt * 0.03527778;
        for (let i = 0; i < responseConsultations.length; i++) {
          const consultation = responseConsultations[i];

          // Tu código actual para agregar contenido al PDF
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text(
              `Nombre del médico: ${consultation.name_doctor ? consultation.name_doctor : ""} - Última consulta: ${consultation.date_consultation_hours? formatHour(consultation.date_consultation_hours) :''}`,
              ptToCm(40),
              ptToCm(40)
          );
          doc.setFontSize(15);
          doc.text(`Paciente: ${this.user.name}`, ptToCm(40), ptToCm(70));
          doc.setDrawColor(155, 155, 155);

          const x1 = ptToCm(40);
          const y1 = ptToCm(75);
          const lineWidth = ptToCm(2);

          doc.setLineWidth(lineWidth);
          const x2 = ptToCm(550);
          const y2 = ptToCm(75);

          doc.line(x1, y1, x2, y2);
          doc.setFontSize(12);
          doc.text(`Resumen de consulta`, ptToCm(40), ptToCm(95));
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          doc.text(`Paciente: ${this.user.name}`, ptToCm(40), ptToCm(115));

          if (consultation.years) {
            doc.text(`Edad: ${consultation.years}`, ptToCm(40), ptToCm(135));
          } else {
            doc.text(`Edad:`, ptToCm(40), ptToCm(135));
          }

          if (this.user.birthday) {
            doc.text(`Fecha Nacimiento:`, ptToCm(120), ptToCm(135));
            doc.text(`${this.user.birthday ? this.dateStringMxFormat(this.user.birthday) : ""}`, ptToCm(220), ptToCm(135));
          } else {
            doc.text(`Fecha Nacimiento:`, ptToCm(120), ptToCm(135));
          }

          doc.text(`Consultorio de mi paciente: ONKIMIA`, ptToCm(40), ptToCm(155));
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`Antecedentes importantes`, ptToCm(40), ptToCm(175));
          const y3 = ptToCm(180);
          const y4 = ptToCm(180);
          doc.line(x1, y3, x2, y4);

          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          const strArrBack = doc.splitTextToSize(responseInfo.important_background ? responseInfo.important_background : '', ptToCm(500))
          const yOffset = ptToCm(210);


          doc.text(strArrBack, ptToCm(40), yOffset);

          const firstParaHeight = doc.getTextDimensions(strArrBack).h;
          const marginBetweenParagraphs = ptToCm(10);

          const yOffsetSecondPara = yOffset + firstParaHeight + marginBetweenParagraphs + ptToCm(10);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text("Signos Vitales/Básicos", ptToCm(40), yOffsetSecondPara);
          const y5 = yOffsetSecondPara + ptToCm(10);
          const y6 = yOffsetSecondPara + ptToCm(10);
          doc.line(x1, y5, x2, y6);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          doc.text(`Altura:`, ptToCm(40), yOffsetSecondPara + ptToCm(35));
          doc.text(`Peso:`, ptToCm(115), yOffsetSecondPara + ptToCm(35));
          doc.text(`I.M.C.:`, ptToCm(190), yOffsetSecondPara + ptToCm(35));
          doc.text(`S.C.T.:`, ptToCm(265), yOffsetSecondPara + ptToCm(35));
          doc.setFont("helvetica", "bold");
          doc.text(`${consultation.height ? consultation.height + " Mts" : "----"}`, ptToCm(70), yOffsetSecondPara + ptToCm(35));
          doc.text(`${consultation.weight ? consultation.weight + " kg" : "----"}`, ptToCm(145), yOffsetSecondPara + ptToCm(35));
          doc.text(`${consultation.imc ? consultation.imc : "----"}`, ptToCm(225), yOffsetSecondPara + ptToCm(35));
          doc.text(`${consultation.body_surface ? consultation.body_surface : "----"}`, ptToCm(300), yOffsetSecondPara + ptToCm(35));
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text("Diagnósticos", ptToCm(40), yOffsetSecondPara + ptToCm(60));
          const y7 = yOffsetSecondPara + ptToCm(70);
          const y8 = yOffsetSecondPara + ptToCm(70);
          doc.line(x1, y7, x2, y8);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          const concatenatedText = consultation.ClinicDiagnostics
              .filter(item => item.name)
              .map(item => `- ${item.name}`)
              .join('\n');

          const strArrBackDiagnostics = doc.splitTextToSize(concatenatedText, ptToCm(500));
          doc.text(strArrBackDiagnostics, ptToCm(40), ptToCm(100) + yOffsetSecondPara);
          const yOffSetDiagSpace = ptToCm(100) + yOffsetSecondPara;
          const twoParaHeight = doc.getTextDimensions(strArrBackDiagnostics).h;

          const yOffsetDiag = yOffSetDiagSpace + twoParaHeight + marginBetweenParagraphs + ptToCm(10);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text("Medicamentos", ptToCm(40), yOffsetDiag);
          const y9 = yOffsetDiag + ptToCm(10);
          const y10 = yOffsetDiag + ptToCm(10);
          doc.line(ptToCm(40), y9, ptToCm(550), y10);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          const concatenatedMedicals = consultation.ClinicRecipes
              .filter(item => item.product)
              .map(item => `${item.indications} - ${item.product}`)
              .join('\n');
          const strArrBackMedicals = doc.splitTextToSize(concatenatedMedicals, ptToCm(500));
          doc.text(strArrBackMedicals, ptToCm(40), yOffsetDiag + ptToCm(30));
          const yOffSetRecipeText = yOffsetDiag + ptToCm(10);
          const threeParaHeight = doc.getTextDimensions(strArrBackMedicals).h;

          const yOffsetRecipe = yOffSetRecipeText + threeParaHeight + marginBetweenParagraphs + ptToCm(10);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text("Tratamiento oncológico sugerido", ptToCm(40), yOffsetRecipe + ptToCm(10));
          const y11 = yOffsetRecipe + ptToCm(20);
          const y12 = yOffsetRecipe + ptToCm(20);
          doc.line(ptToCm(40), y11, ptToCm(550), y12);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          doc.text(`Diagnóstico molecular: ${consultation.ClinicOncologicalTreatment?.molecular_diagnosis ? 'Si' : 'No'} `, ptToCm(40), yOffsetRecipe + ptToCm(35));
          doc.text(`Tratamiento`, ptToCm(40), yOffsetRecipe + ptToCm(50));
          let concatenatedTreatment = '-';
          if (consultation.ClinicOncologicalTreatment) {
            concatenatedTreatment = consultation.ClinicOncologicalTreatment.ClinicOncologicalLines
                .filter(item => item.treatment)
                .map(item => `${item.description} - ${item.treatment ? item.treatment : ""}`)
                .join('\n');
          }
          const strArrBackTreatment = doc.splitTextToSize(concatenatedTreatment, ptToCm(500));
          doc.text(strArrBackTreatment, ptToCm(40), yOffsetRecipe + ptToCm(65));
          const yOffSetTreatmentText = yOffsetRecipe + ptToCm(50);
          const fourParaHeight = doc.getTextDimensions(strArrBackTreatment).h;

          const yOffsetTreatments = yOffSetTreatmentText + fourParaHeight + marginBetweenParagraphs + ptToCm(10);
          doc.text(`Línea de tratamiento: ${consultation.ClinicOncologicalTreatment?.line_treatment ? consultation.ClinicOncologicalTreatment.line_treatment : "----"}`, ptToCm(40), yOffsetTreatments);
          doc.text(`Respuesta de tratamiento: ${consultation.ClinicOncologicalTreatment?.answer_treatment ? consultation.ClinicOncologicalTreatment.answer_treatment : "----"}`, ptToCm(170), yOffsetTreatments);

          const strArrBackSpecs = doc.splitTextToSize(consultation.ClinicOncologicalTreatment?.specifications ? consultation.ClinicOncologicalTreatment.specifications : "", ptToCm(500));
          const yOffSetText = yOffsetRecipe + ptToCm(50);
          const fiveParaHeight = doc.getTextDimensions(strArrBackSpecs).h;
          const yOffsetDescription = yOffSetText + fiveParaHeight + marginBetweenParagraphs + ptToCm(10);
          const availableSpace = doc.internal.pageSize.height - yOffsetDescription;
          if (availableSpace < ptToCm(700)) {
            doc.text(`Especificaciones:`, ptToCm(40), yOffsetTreatments + ptToCm(15));
            doc.text(strArrBackSpecs, ptToCm(40), yOffsetTreatments + ptToCm(25));
            doc.setFont("helvetica", "bold");
            doc.setFontSize(12);
            doc.text("Estudios Requeridos", ptToCm(40), yOffsetDescription + ptToCm(70));
            const y13 = yOffsetDescription + ptToCm(80);
            const y14 = yOffsetDescription + ptToCm(80);
            doc.line(ptToCm(40), y13, ptToCm(550), y14);
            doc.text("Orden de laboratorio", ptToCm(40), yOffsetDescription + ptToCm(100));
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            const concatenatedStudies = consultation.ClinicOrderStudy.ClinicStudiesLines
                .filter(item => item.product)
                .map(item => ` - ${item.product ? item.product : ""}`)
                .join('\n');
            const strArrBackStudies = doc.splitTextToSize(concatenatedStudies, ptToCm(500));
            doc.text(strArrBackStudies, ptToCm(40), yOffsetDescription + ptToCm(120));
          } else {
            const offSetPage = ptToCm(10);
            doc.addPage();
            doc.text(`Especificaciones:`, ptToCm(40), offSetPage + ptToCm(40));
            doc.text(strArrBackSpecs, ptToCm(40), offSetPage + ptToCm(60));
            doc.setFont("helvetica", "bold");
            doc.setFontSize(12);
            doc.text("Estudios Requeridos", ptToCm(40), ptToCm(100) + fiveParaHeight);
            const y13 = offSetPage + ptToCm(100) + fiveParaHeight;
            const y14 = offSetPage + ptToCm(100) + fiveParaHeight;
            doc.line(ptToCm(40), y13, ptToCm(550), y14);
            doc.text("Orden de laboratorio", ptToCm(40), ptToCm(140) + fiveParaHeight);
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            const concatenatedStudies = consultation.ClinicOrderStudy.ClinicStudiesLines
                .filter(item => item.product)
                .map(item => ` - ${item.product ? item.product : ""}`)
                .join('\n');
            const strArrBackStudies = doc.splitTextToSize(concatenatedStudies, ptToCm(500));
            doc.text(strArrBackStudies, ptToCm(40), ptToCm(160) + fiveParaHeight);
          }

          // Solo agrega una nueva página si no es la última consulta
          if (i < responseConsultations.length - 1) {
            doc.addPage();
          }
        }
        doc.setFontSize(10);
        if (this.getUser.department_id === 13) {


          const addFooters = async (doc) => {
            const pageCount = doc.internal.getNumberOfPages();
            let responsePatient;
            if (this.getUser.address_id) {
              responsePatient = await this.findPatientOne(this.getUser.address_id);
            }
            let responseDataSignature = null
            if (this.getUser.id) {
              this.show = true
              responseDataSignature = await this.getFindFullName(this.getUser.id);
              this.show = false
            }

            for (let i = 1; i <= pageCount; i++) {
              doc.setPage(i);
              const footerName = `${this.getUser.name || ''} ${this.getUser.study_field ? this.getUser.study_field : ''} `;
              const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
              const footerAddress = `${responsePatient ? responsePatient.contact_address_complete : ''}`;
              const footerPosition = doc.internal.pageSize.getHeight();
              doc.text(footerAddress, 1.5, footerPosition - 0.5);
              doc.text(footerText, 1.5, footerPosition - 1);
              doc.text(footerName, 1.5, footerPosition - 1.5);
              if (responseDataSignature && responseDataSignature.signatureFile) {
                const img2 = new Image();
                img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
                doc.addImage(img2, "png", 16, footerPosition - 4, 3, 3);
              }
            }
          };
          await addFooters(doc);
        }
        this.show = false;
        doc.save(`historial-clinico-${this.user.name}.pdf`);
      }catch (error){
        console.log(error)
        this.show = false;
        alert('Error al generar pdf', error)
      }

    },
    async saveImage() {

    },
    async initialize() {
      await this.findDoctor()
      await this.getOne();
      await this.tabsFind()
      await this.getPriceListSelect()

      await this.patientVerify(this.user.id)
      await this.verifyInfo(this.patient_id);
    },
    async verifyInfo(id) {
      const response = await this.findPatientOneInformation(id);
      this.allergiesInfo = null;
      if (response) {
        if (!response.have_allergies) {
          this.allergiesInfo = response.allergies
        }
      }

    },
    async patientVerify(id) {

      const response = await this.findPricelistPatient(id);


      this.select_price_list.id = response.id
      this.select_price_list.value = response.name
      this.user.property_product_pricelist = response.id

    },
    changeChemoValue() {
      if (this.user.chemo_suspension === '') {
        this.user.chemo_suspension = true
        return
      }
      this.user.reason_suspension = ''

    },
    changeExtraValue() {
      if (this.user.check_medical === '') {
        this.user.check_medical = true
        return
      }
      this.user.extra_medical_id = null
      this.extra_select.id = null
      this.extra_select.value = 'Seleccione un médico'

    },
    async executeToShare() {
      await this.$root.$emit('chargeShare')
    },
    async executeToLaboratories() {
      await this.$root.$emit('chargeLaboratories')
    },
    async executeToBio() {
      await this.$root.$emit('chargeBio')
    },
    async executeToQuestion() {
      await this.$root.$emit('chargePatientQuestion')
    },
    async executeSchema() {
      await this.$root.$emit('chargeSchema')
    },
    async executeInsurers() {
      await this.$root.$emit('chargeInsurers')
    },
    async executeFilesTreatment() {
      await this.$root.$emit('chargeFilesTreatment')
    },
    async executeInterConsultations() {
      await this.$root.$emit('chargeInterConsultation')
    },
    async executeConsultation() {
      await this.$root.$emit('chargeConsultation')
    },
    async executeInfoHistory() {
      await this.$root.$emit('chargePatientHistory')
    },
    async executeRecipe() {
      await this.$root.$emit('chargeRecipe')
    },
    changeRegimen() {
      this.user.l10n_mx_edi_fiscal_regime = this.regimen_select.id;
    },
    changeGender() {
      this.user.gender = this.gender_select.id;
    },
    changeIdentifiedGender() {
      this.user.identified_gender = this.identified_gender_select.id;
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async tabsFind() {
      this.tabs = await this.nameClinic()
    },
    async pricelistVerify() {
      this.user.property_product_pricelist = this.select_price_list.id;
    },

    async groupsVerify() {
      await this.searchGroup(this.select_group.id)
    },
    activeTab(id) {

      this.idactive = id

    },
    async getOne() {
      let regimen = null
      let gender = null
      const id = this.$route.params.id;

      this.patient_id = parseInt(id);
      const response = await this.findPatientOne(id);
      this.avatarImage = response.photo_user;

      if (response.l10n_mx_edi_fiscal_regime) {
        for (const a of this.regimens) {
          if (a.id === response.l10n_mx_edi_fiscal_regime) {
            regimen = a.value
          }
        }
        this.regimen_select.id = response.l10n_mx_edi_fiscal_regime
        this.regimen_select.value = regimen
      } else {
        this.regimen_select.id = ''
        this.regimen_select.value = ''
      }
      if (response.gender) {
        for (const a of this.genders) {
          if (a.id === response.gender) {
            gender = a.value
          }
        }
        this.gender_select.id = response.gender
        this.gender_select.value = gender
      } else {
        this.gender_select.id = ''
        this.gender_select.value = ''
      }
      if (response.identified_gender) {
        for (const a of this.gendersIdentified) {
          if (a.id === response.identified_gender) {
            gender = a.value
          }
        }
        this.identified_gender_select.id = response.gendersIdentified
        this.identified_gender_select.value = gender
      } else {
        this.identified_gender_select.id = ''
        this.identified_gender_select.value = ''
      }
      if (response.chemo_suspension) {
        const result = this.chemo_options.find((a) => a.id === response.reason_suspension);
        this.chemo_select.id = result.id;
        this.chemo_select.value = result.value;
      } else {
        this.chemo_select.id = ''
        this.chemo_select.value = 'Seleccione una razón'
      }
      if (response.check_medical) {
        const result = this.medicals.find((a) => a.id === response.extra_medical_id);
        this.extra_select.id = result.id;
        this.extra_select.value = result.value;
      } else {
        this.extra_select.id = ''
        this.extra_select.value = 'Seleccione un Médico'
      }
      this.user = response;
      if (!this.user.patient_file_number) {
        this.user.patient_file_number = await this.findConsecutiveId();
      }
      if (response.ResCountry) {
        await this.verifyCountry(response)
      }

      await this.verifyDoctor(response)
    },
    async verifyCountry(data) {
      this.name_country = data.ResCountry ? data.ResCountry.name : ''
      this.select_state.id = data.ResCountryState ? data.ResCountryState.id : ''
      this.select_state.value = data.ResCountryState ? data.ResCountryState.name : ''
      if (data.ResCity) {
        this.select_cities.value = data.ResCity.name
        this.select_cities.id = data.ResCity.id
      } else {
        this.select_cities.value = ''
        this.select_cities.id = ''
      }
      const item = {
        item: {
          id: data.ResCountry.id
        }
      }
      await this.countryVerify(item)
    },

    async verifyDoctor(data) {
      this.select_medical.id = data.ResPartnerAppointment.id
      this.select_medical.value = data.ResPartnerAppointment.name


    },
    async getGroupSelect() {
      this.groups = []
      const response = await this.findAppointmentGroups()
      for (const a of response) {
        if (!a.is_place) {
          this.groups.push({
            id: a.id,
            value: a.group_name,
            verifed: a.is_place
          })
        }

      }
    },
    changeMedical() {
      this.user.appointee_id = this.select_medical.id;
    },
    change_chemo() {
      this.user.reason_suspension = this.chemo_select.id;
    },
    change_extra_medical() {
      this.user.extra_medical_id = this.extra_select.id;
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }

    },
    async findDoctor() {
      const response = await this.findAppointmentDoctorPlace()
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }
    },
    async verifiedForm() {

      if (this.user.name === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo nombre requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.phone) {
        if (this.user.phone.length !== 10) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo Telefono necesita 10 caracteres",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
      }
      if (this.user.email !== "") {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(this.user.email) === false) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo email no es un correo",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }

      }
      if (!this.user.birthday) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo fecha de nacimiento requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.mobile) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Celular requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.mobile.length !== 10) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campo Celular necesita 10 caracteres",
            icon: "error",
            variant: "warning",
          },
        });
        return false;
      }
      if (!this.user.country_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Pais requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.state_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo estado  requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.city_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo municipio requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.property_product_pricelist) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Tipo de Paciente requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.chemo_suspension) {
        if (!this.user.reason_suspension) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo Razón no debe ir vacio',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false
        }
      }
      if (this.user.check_medical) {
        if (!this.user.extra_medical_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo doctor extra es requerido',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false
        }
      }
      return true


    },
    async handleImage() {
      const file = document.querySelector(`#file_patient_photo`).files[0];

      const file_convert = await this.getBase64(file)

      file_convert.onload = async (event) => {
        this.show = true;
        this.avatarImageTmp = event.target.result;
        const response = await this.updatePhotoPatient({id:this.patient_id, data:{file:  this.avatarImageTmp }})
        if (response){
          this.show = false;
          this.avatarImage = event.target.result;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Imagen se subio correctamente',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }else{
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al subir la imagen',
              icon: 'error',
              variant: 'warning',
            },
          });
        }
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async updateUser(user) {
      this.user.city_id = this.select_cities.id;
      this.user.reason_suspension = this.user.chemo_suspension ? this.chemo_select.id : null;
      this.user.comments_suspension = this.user.chemo_suspension ? this.user.comments_suspension : '';
      let response;
      delete this.user.ResCountryState
      delete this.user.ResCity
      delete this.user.ResCountry
      delete this.user.ResPartnerAppointment
      const re = await this.verifiedForm()
      if (!re) {
        return
      }
      this.show = true
      response = await this.updatePatient(this.user)

      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario Editado',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.findDoctor()
        await this.getOne();
        await this.tabsFind()
        await this.getPriceListSelect()

        await this.patientVerify(this.user.id)
        await this.verifyInfo(this.patient_id);
        this.show = false

      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: 'error',
            variant: 'warning',
          },
        });

      }
    },
    async countryVerify(item) {
      this.user.country_id = item.item.id
      this.states = []
      const response = await this.findCountryState(this.user.country_id);
      for (const a of response) {
        this.states.push({
          id: a.id,
          value: a.name,
        })
      }
      await this.searchCities()
    },
    async searchCities() {
      this.cities = []
      let data = {}
      if (this.user.state_id) {
        data = {
          country_id: this.user.country_id,
          state_id: this.user.state_id
        }
      } else {
        data = {
          country_id: this.user.country_id,
          state_id: null
        }
      }
      const response = await this.findCountryCities(data);
      for (const a of response) {
        this.cities.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async selectState() {
      this.user.state_id = this.select_state.id;

      await this.searchCities()
    },
    async getCountrySelect(text) {
      if (text === '' || text === undefined) {
        this.countries = []
        return
      }
      this.countries = []
      let cou = []
      const response = await this.findCountry(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        })
      }
      this.countries = [{
        data: cou,
      }]

    }


  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
